<template>
  <div :class="'m-p profile-container ' + ( this.$store.state.profileSideBar ? 'open' : '' )">  
      <Notifications />
  </div>
   
</template>
<script>
import { defineAsyncComponent } from 'vue'
export default {
name: 'My Notifications',
components: {
  Notifications: defineAsyncComponent( () => import('@/components/Profile/Notifications.vue') ),
},
}
</script>